import { BASE } from "./index";
import axios from "./http";

const payment = {
    //仓配置列表
    productlist: (params) =>axios.post(`${BASE.PRO1}/logistics/payment/config/list`,  params ),
    //筛选项枚举
    configsearch: (params) =>axios.post(`${BASE.PRO1}/logistics/payment/config/search`,  params ),
    //支付配置支持到仓 - 是否售卖期
    salescycle: (params) =>axios.post(`${BASE.PRO1}/is/sales/cycle`,  params ),
    //支付
    configupdate: (params) =>axios.post(`${BASE.PRO1}/logistics/payment/config/update`,  params ),
    //支付配置支持到仓 - 批量修改配置
    configbatchupdate: (params) =>axios.post(`${BASE.PRO1}/logistics/payment/config/batch/update`,  params ),
    //修改记录
    configloglist: (params) =>axios.post(`${BASE.PRO1}/logistics/payment/config/log/list`,  params ),
};

export default payment;
