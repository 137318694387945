import { BASE } from './index';
import axios from './http';
const contract = {
    // 合同列表
    getcontractlist: params => axios.post(`${BASE.PRO1}/contract/list`, params ),
    // 停止签约
    getcontractstop: params => axios.post(`${BASE.PRO1}/contract/stop`, params ),
    // 签约列表
    getcontractlog: params => axios.post(`${BASE.PRO1}/supplier/contract/log`, params ),
 
};

export default contract;
