import { BASE } from "./index";
import axios from "./http";

const INDEX = {
  login: (params) => axios.post(`${BASE.PRO}/login`, params),
  //修改密码
  editPassword: (params) => axios.post(`${BASE.PRO}/edit/password`, params),

  loginout: (params) => axios.post(`${BASE.PRO}/api/manage/logout`, params),
  //中心账号列表
  businesslist: (params) => axios.post(`${BASE.PRO}/business/list`, params),
  //中心账号列表启用停用
  businessstatus: (params) => axios.post(`${BASE.PRO}/business/status`, params),
  //中心账号列表添加
  addbusiness: (params) => axios.post(`${BASE.PRO}/add/business`, params),
  //获取所有可配置的自提点
  unusedall: (params) =>
    axios.get(`${BASE.PRO}/delivery/unused/all`, { params }),
  //获取所有可配置的城市仓
  logisticsunusedall: (params) =>
    axios.get(`${BASE.PRO}/logistics/unused/all`, { params }),
  //获取商品
  productdetail: (params) =>
    axios.post(`${BASE.PRO1}/product/detail`,  params ),
};

export default INDEX;
