import { BASE } from './index';
import axios from './http';
const decoration = {
    // 活动列表
    activity_list: params => axios.post(`${BASE.PRO1}/activity/list`,  params ),
    // 添加活动
    activity_add: params => axios.post(`${BASE.PRO1}/activity/add`,  params ),
    // 编辑活动
    activity_edit: params => axios.post(`${BASE.PRO1}/activity/edit`,  params ),
    // 编辑活动详情
    activity_detail: params => axios.post(`${BASE.PRO1}/activity/detail`,  params ),
    // 活动启用禁用
    activity_open_close: params => axios.post(`${BASE.PRO1}/activity/open/close`,  params ),
    // 分区列表(不分页)
    region_list: params => axios.post(`${BASE.PRO1}/activity/region/list`,  params ),
    // 模板详情
    template_list: params => axios.post(`${BASE.PRO1}/activity/template/list`,  params ),
    // 添加模板
    template_add: params => axios.post(`${BASE.PRO1}/activity/template/add`,  params ),
    // 添加模板删除
    template_delete: params => axios.post(`${BASE.PRO1}/activity/template/delete`,  params ),
    // 添加模板编辑
    template_edit: params => axios.post(`${BASE.PRO1}/activity/template/edit`,  params ),
    // 添加模板详情
    template_detail: params => axios.post(`${BASE.PRO1}/activity/template/detail`,  params ),
    // 活动商品列表
    activity_product: params => axios.post(`${BASE.PRO1}/activity/product`,  params ),
    // 审核
    activity_product_audit: params => axios.post(`${BASE.PRO1}/activity/product/audit`,  params ),
    // 删除
    activity_product_del: params => axios.post(`${BASE.PRO1}/activity/product/del`,  params ),
    // 大促列表
    promotionactivitylist: params => axios.post(`${BASE.PRO1}/promotion/activity/list`,  params ),
    // 大促列表开启活动
    promotionactivityopen: params => axios.post(`${BASE.PRO1}/promotion/activity/open`,  params ),
    // 大促列表开启活动添加
    promotionactivityadd: params => axios.post(`${BASE.PRO1}/promotion/activity/add`,  params ),
    // 大促列表开启活动编辑
    promotionactivityedit: params => axios.post(`${BASE.PRO1}/promotion/activity/edit`,  params ),
    // 大促列表活动商品列表
    promotionactivityproduct: params => axios.post(`${BASE.PRO1}/promotion/activity/product`,  params ),
    // 大促列表活动商品列表删除商品
    promotionactivitydelete: params => axios.post(`${BASE.PRO1}/promotion/activity/product/delete`,  params ),
    // 大促列表活动商品列表审核通过
    promotionactivityaudit: params => axios.post(`${BASE.PRO1}/promotion/activity/product/audit`,  params ),
    // 大促列表活动商品列表审核通过
    promotionactivitydetail: params => axios.post(`${BASE.PRO1}/promotion/activity/detail`,  params ),
    // 下载上传模版
    activitytemplatefile: params => axios.post(`${BASE.PRO1}/promotion/activity/template/file`,  params ),
    // 选择集配中心列表
    collective: params => axios.get(`${BASE.PRO1}/business/collective`,  {params} ),

};

export default decoration;
