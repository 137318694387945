import { BASE } from "./index";
import axios from "./http";

const biexport = {

    //城市仓导出订单
    tdcorderexport: (params) =>
        axios.post(`${BASE.EXPORT_CENTER}/bi/tdc/order/export`, params),

    //城市仓导出结算管理列表
    tdcsettlementlistexport: (params) =>
        axios.post(`${BASE.EXPORT_CENTER}/bi/tdc/settlement/list/export`, params),
    //城市仓导出结算管理详情
    tdcsettlementdetailexport: (params) =>
        axios.post(`${BASE.EXPORT_CENTER}/bi/tdc/settlement/detail/export`, params),
    //自提点导出订单
    deliveryorderexport: (params) =>
        axios.post(`${BASE.EXPORT_CENTER}/bi/delivery/order/export`, params),
    //大促活动提报商品列表导出
    activityproductexport: (params) =>
        axios.post(`${BASE.EXPORT_CENTER}/bi/promotion/activity/product/export`, params),
    //供货单导出
    orderlistexport: (params) =>
        axios.post(`${BASE.EXPORT_CENTER}/bi/supplier/order/list/export`, params),
};
export default biexport;
